import { useTheme } from '@mui/material/styles'
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary'
import { productType } from 'constants/common'
import EventIcon from '@mui/icons-material/Event'
import Typography from 'components/commons/Typography'
import Stack from 'components/commons/Stack'
import { useTranslation } from 'react-i18next'

const ProductLabel = ({ type }: { type: productType }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const renderLabel = () => {
    switch (type) {
      case 'content':
        return t('productItemLabelDigitalContent')
      case 'event':
        return t('productItemLabelEvent')
      case 'session':
        return t('productItemLabelLiveSession')
      default:
        return ''
    }
  }

  return (
    <Stack
      direction={'row'}
      sx={{
        width: 'fit-content',
        padding: `4px 8px`,
        borderRadius: `100px`,
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.border.minimal}`,
        gap: 1,
        alignItems: 'center',
      }}
    >
      {type === 'event' ? <EventIcon /> : <VideoLibraryIcon />}
      <Typography
        sx={{
          fontWeight: '600',
          color: theme.palette.tiptip[500],
          textTransform: 'capitalize',
        }}
      >
        {renderLabel()}
      </Typography>
    </Stack>
  )
}

export default ProductLabel
