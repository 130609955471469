import { Link, useTheme } from '@mui/material'
import Box from 'components/commons/Box'
import Stack from 'components/commons/Stack'
import ContentModal from 'components/domains/Content/ContentModal'
import { useState } from 'react'
import {
  StyledCard,
  StyledImage,
  StyledButtonContainer,
  StyledExclusiveContentContainer,
  StyledExclusiveLabel,
  StyledBoxType,
  StyledTitle,
  StyledPriceContainer,
  StyledPrice,
  StyledSalePrice,
  StyledPurchasedBadge,
  StyledInfoSection,
} from './styled'
import { StyledButtonPlay } from 'components/commons/ButtonPlay'
import LockRoundedIcon from '@mui/icons-material/LockRounded'
import handleErrorImage from 'helpers/error-image'
import StatusLabel from './StatusLabel'
import EventStatus from 'components/commons/EventStatus'
import FmdGoodIcon from '@mui/icons-material/FmdGood'
import Typography from 'components/commons/Typography'
import dateFormatter, { checkMultipleDay } from 'helpers/formats/date'
import CreatorCaption from 'components/commons/CreatorCaption'
import CurrencyFormatter from 'helpers/formats/currency'
import ProductLabel from './ProductLabel'
import DiamondIcon from '@mui/icons-material/Diamond'
import ShopIcon from '@mui/icons-material/Shop'
import { useRouter } from 'next/router'
import trackEvent from 'src/trackers'
import isEmpty from 'helpers/is-empty'
import { getLocalStorage } from 'helpers/local-storage'
import { IGeneralProductCard } from './interface'
import { useTranslation } from 'react-i18next'

function GeneralProductCard({
  productType = 'content',
  id,
  image,
  cover,
  teaser,
  startDate,
  endDate,
  href,
  eventStatus,
  showVideoPlayer,
  trackDefaultStates,
  onClick = () => {},
  playable,
  locked,
  typeName,
  status,
  location,
  title,
  creatorName,
  salePrice,
  isVerified,
  accessSource,
  hidePrice = false,
  price,
  sessionType,
  sxContainer,
  className = '',
  pathname = '',
  groupName = '',
  creatorId = 'unknown',
  creatorUserId = 'unknown',
  creatorGroup = '',
  creatorIsCommunityLeader = '',
  categoryId = 'unknown',
  categoryName = 'unknown',
  sectionId = '',
  sectionTitle = '',
  sectionContentType = '',
  eventTimezone,
}: IGeneralProductCard) {
  const { t } = useTranslation()
  const theme = useTheme()
  const { query } = useRouter()

  const trackerData = { pathname: pathname, query }
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isTeaserShown, setIsTeaserShown] = useState(false)
  const userId = getLocalStorage('userId')?.id
  const isSelf = userId === creatorUserId

  const isEvent = productType === 'event'
  const isContent = productType === 'content'
  const isSession = productType === 'session'

  const isEventMultipleDays = checkMultipleDay(
    startDate,
    endDate,
    eventTimezone,
  )

  const onCloseVideoPlayer = () => {
    setIsOpenModal(false)
    setIsTeaserShown(false)
  }

  const renderImageSection = () => {
    return (
      <StyledImage
        sx={
          isEvent &&
          eventStatus && {
            img: {
              borderBottomLeftRadius: '0',
              borderBottomRightRadius: '0',
            },
          }
        }
      >
        {playable && (
          <StyledButtonContainer
            onClick={(e) => {
              e.stopPropagation()
              setIsTeaserShown(true)
              setIsOpenModal(true)
            }}
            id="b-content-action-teaser-buton"
            sx={{
              svg: {
                fontSize: {
                  xs: 32,
                  md: 48,
                },
              },
            }}
            buttonVariant="nude"
          >
            <StyledButtonPlay />
          </StyledButtonContainer>
        )}
        {locked && (
          <StyledExclusiveContentContainer>
            <LockRoundedIcon
              fontSize="large"
              sx={(theme) => ({
                color: theme.palette.tiptip[500],
                marginRight: '4px',
              })}
            />
            <Stack>
              <StyledExclusiveLabel>
                {t('exclusiveFor')}
              </StyledExclusiveLabel>
              <StyledExclusiveLabel
                sx={{ fontWeight: '700 !important' }}
              >
                {t('communityMember')}
              </StyledExclusiveLabel>
            </Stack>
          </StyledExclusiveContentContainer>
        )}
        <img
          src={image || cover}
          alt={`${productType}-card-image`}
          loading="lazy"
          onError={({ currentTarget }) => {
            handleErrorImage({
              currentTarget,
              type: productType,
            })
          }}
        />
      </StyledImage>
    )
  }

  const renderStatus = () => {
    if (isEvent && eventStatus) {
      return <EventStatus eventStatusName={eventStatus} />
    }
    if (isContent || isSession) {
      return (
        <StatusLabel
          name={typeName}
          status={status}
          type={productType}
        />
      )
    }
    return null
  }

  const renderLocation = () => {
    if (location && location !== '') {
      return (
        <StyledBoxType>
          <FmdGoodIcon
            sx={{
              fontSize: '14px',
              marginRight: '4px',
              color: theme.palette.primary.main,
            }}
          />
          <Typography
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              color: theme.palette.text.secondary,
              maxWidth: '120px',
            }}
          >
            {location}
          </Typography>
        </StyledBoxType>
      )
    }
    return null
  }

  const renderPrice = () => {
    if (!isSession && !accessSource && !hidePrice)
      return (
        <StyledPriceContainer
          sx={
            isEvent && {
              flexDirection: 'column',
              alignItems: 'flex-start',
              height: '48px',
              justifyContent: 'center',
            }
          }
        >
          <StyledPrice
            className={`c-common-title-${productType}-price`}
          >
            {salePrice === 0
              ? t('digitalContentListItemFreeLabel')
              : CurrencyFormatter(salePrice)}
          </StyledPrice>

          {(!isEvent ? salePrice !== 0 : true) &&
            salePrice !== price && (
              <StyledSalePrice>
                {CurrencyFormatter(price)}
              </StyledSalePrice>
            )}
        </StyledPriceContainer>
      )

    if (isSession)
      return (
        <>
          <StyledPrice sx={{ textTransform: 'capitalize' }}>
            {sessionType}
          </StyledPrice>
        </>
      )
    return null
  }

  const renderDate = () => {
    let dateLabel
    if (isEventMultipleDays) {
      dateLabel = `${dateFormatter({
        date: startDate,
        format: 'DD MMM',
        timezone: eventTimezone,
      })} - ${dateFormatter({
        date: endDate,
        format: 'DD MMM',
        timezone: eventTimezone,
      })}, ${dateFormatter({
        date: startDate,
        format: 'HH:mm',
        timezone: eventTimezone,
      })} ${eventTimezone?.abbreviation}`
    } else {
      dateLabel = `${dateFormatter({
        date: startDate,
        format: 'dddd, DD MMM, HH:mm',
        timezone: eventTimezone,
      })} ${eventTimezone?.abbreviation}`
    }

    return (
      <Typography
        sx={(theme) => ({
          color: theme.palette.text.primary,
          ...theme.typography.normalRegular,
          height: '40px',
        })}
      >
        {dateLabel}
      </Typography>
    )
  }

  const renderPurchasedBadge = () => {
    switch (accessSource) {
      case 'subscription':
        return (
          <StyledPurchasedBadge
            badgeColor={theme.palette.purple[300]}
          >
            <DiamondIcon /> Bebas Akses
          </StyledPurchasedBadge>
        )
      case 'direct_purchase':
        return (
          <StyledPurchasedBadge
            badgeColor={theme.palette.tiptip[500]}
          >
            <ShopIcon /> Sudah Dibeli
          </StyledPurchasedBadge>
        )
      default:
        return null
    }
  }

  const renderCreatorName = () => {
    return (
      <CreatorCaption
        title={creatorName}
        isVerified={isVerified}
        size="sm"
        className="c-common-title-content-creator"
        isRegular
        sx={(theme) => ({
          color: theme.palette.text.secondary,
        })}
      />
    )
  }

  const renderTitle = () => {
    return (
      <StyledTitle
        className={`c-common-title-${productType}-name`}
        href={href}
      >
        {title}
      </StyledTitle>
    )
  }

  const renderHeadSection = () => {
    return (
      <Stack>
        {renderImageSection()}
        {renderStatus()}
      </Stack>
    )
  }

  const renderInfoSection = () => {
    return (
      <StyledInfoSection gap={'8px'}>
        <Stack gap={'4px'}>
          {renderLocation()}
          {renderTitle()}
          {isEvent && renderDate()}
          {renderCreatorName()}
        </Stack>
        <Box
          sx={(theme) => ({
            borderTop: `2px solid ${theme.palette.border.minimal}`,
            position: 'relative',
          })}
        />
        {renderPrice()}
        <Stack>
          {renderPurchasedBadge()}
          <ProductLabel type={productType} />
        </Stack>
      </StyledInfoSection>
    )
  }

  const redirectPage = () => {
    if (pathname !== '' && productType === 'session') {
      trackEvent.browse('select_session', trackerData, {
        state_sub_name: groupName,
        creator_id: creatorId,
        creator_user_id: creatorUserId,
        creator_category_id: categoryId,
        creator_category: categoryName,
        creator_group: creatorGroup,
        is_community_leader: creatorIsCommunityLeader,
        session_id: id,
        session_title: title,
        creator_name: creatorName,
        selected_browse_and_search_menu: !isEmpty(sectionId)
          ? undefined
          : 'session_tab',
        section_id: !isEmpty(sectionId) ? sectionId : undefined,
        section_title: !isEmpty(sectionTitle)
          ? sectionTitle
          : undefined,
        section_content_type: !isEmpty(sectionContentType)
          ? sectionContentType
          : undefined,
        is_creator_own_profile: isSelf,
        session_type: sessionType,
        session_estimate_start_time: startDate,
      })
    }
  }

  return (
    <div style={{ position: 'relative' }} className={className}>
      <Link
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          zIndex: 3,
        }}
        href={href}
      />
      <StyledCard
        onClick={(e) => {
          e.preventDefault()
          redirectPage()
          onClick()
        }}
        sx={{
          ...sxContainer,
        }}
      >
        {renderHeadSection()}
        {renderInfoSection()}
      </StyledCard>
      <ContentModal
        isOpen={isOpenModal}
        setOpen={setIsOpenModal}
        poster={cover}
        teaser={teaser}
        showVideoPlayer={showVideoPlayer}
        isTeaserShown={isTeaserShown}
        setIsTeaserShown={setIsTeaserShown}
        trackEventDefaultStates={trackDefaultStates}
        onCloseVideoPlayer={onCloseVideoPlayer}
      />
    </div>
  )
}

export default GeneralProductCard
