import { useTheme } from '@mui/material/styles'
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import FileOpenIcon from '@mui/icons-material/FileOpen'
import AudiotrackIcon from '@mui/icons-material/Audiotrack'
import ImageIcon from '@mui/icons-material/Image'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { productType, sessionStatus } from 'constants/common'
import Typography from 'components/commons/Typography'
import Stack from 'components/commons/Stack'

const StatusLabel = ({
  name,
  type = 'content',
  status,
}: {
  name: string
  type?: productType
  status?: sessionStatus
}) => {
  const theme = useTheme()

  let bgColor
  let textColor
  let iconColor
  let typeIcon
  if (type === 'content') {
    switch (name.toLocaleLowerCase()) {
      case 'video':
        typeIcon = <VideoLibraryIcon />
        bgColor = theme.palette.purple[100]
        textColor = theme.palette.purple[500]
        iconColor = theme.palette.purple[500]
        break
      case 'document':
        typeIcon = <TextSnippetIcon />
        bgColor = theme.palette.orange[100]
        textColor = theme.palette.orange[500]
        iconColor = theme.palette.orange[500]
        break
      case 'aset digital':
        typeIcon = <FileOpenIcon />
        bgColor = theme.palette.tiptip[100]
        textColor = theme.palette.tiptip[500]
        iconColor = theme.palette.tiptip[500]
        break
      case 'audio':
        typeIcon = <AudiotrackIcon />
        bgColor = theme.palette.green[100]
        textColor = theme.palette.green[500]
        iconColor = theme.palette.green[500]
        break
      case 'gambar':
        typeIcon = <ImageIcon />
        bgColor = theme.palette.blue[100]
        textColor = theme.palette.blue[500]
        iconColor = theme.palette.blue[500]
        break
      default:
        typeIcon = <></>
        textColor = theme.palette.text.primary
        null
    }
  }

  if (type === 'session') {
    switch (status.toLocaleLowerCase()) {
      case 'upcoming':
        bgColor = theme.palette.orange[100]
        textColor = theme.palette.orange[500]
        break
      case 'completed':
        bgColor = theme.palette.grey[100]
        textColor = theme.palette.text.primary
        break
      case 'live':
        typeIcon = (
          <FiberManualRecordIcon sx={{ width: 14, height: 14 }} />
        )
        bgColor = theme.palette.tiptip[100]
        textColor = theme.palette.tiptip[500]
        iconColor = theme.palette.tiptip[500]
        break
      default:
        null
    }
  }

  return (
    <Stack
      sx={{
        background: bgColor,
        textAlign: 'center',
        borderBottomLeftRadius: '12px',
        borderBottomRightRadius: '12px',
        paddingY: '4px',
        justifyContent: 'center',
        gap: '6px',
        alignItems: 'center',
        svg: {
          color: iconColor,
        },
      }}
      direction={'row'}
    >
      {typeIcon}
      <Typography
        sx={{
          color: textColor,
          textTransform: 'capitalize',
        }}
        fontSize={'12px'}
        fontWeight={'bold'}
      >
        {name || status}
      </Typography>
    </Stack>
  )
}

export default StatusLabel
