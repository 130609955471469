import apiClient from 'helpers/api-client'
import { getIdToken } from 'helpers/auth'
import { sendLog } from 'helpers/log'
import { libraryConst } from 'constants/common'
import { search } from 'services/content'
import { isGuestMode } from 'helpers/cookie'
import { generateShortUuid } from 'helpers/generate/uuid'

export async function getBookingDetail(bookingId) {
  if (!bookingId) {
    throw 'ID not exist'
  }

  return apiClient({
    url: `/content/guest/v3/booking/${bookingId}`,
    method: 'GET',
    withoutHeaders: true,
  })
}

export async function createEventBooking(payload) {
  try {
    const { data } = await apiClient({
      method: 'POST',
      url: isGuestMode()
        ? '/content/guest/v1/event/book'
        : '/content/v1/event/book',
      data: payload,
      headers: { 'Idempotent-Key': generateShortUuid() },
      token: getIdToken(),
    })
    return data.data
  } catch (error) {
    sendLog(error)
    throw error
  }
}

export async function cancelEventBooking(book_session_id) {
  try {
    return apiClient({
      method: 'DELETE',
      url: isGuestMode()
        ? `/content/guest/v1/event/book/${book_session_id}`
        : `/content/v1/event/book/${book_session_id}`,
      token: getIdToken(),
    })
  } catch (error) {
    sendLog(error)
    throw error
  }
}

export async function saveAdditionalInfo({
  book_session_id,
  additional_info,
  send_to_participant_email,
}) {
  try {
    const res = await apiClient({
      method: 'PUT',
      url: isGuestMode()
        ? `/content/guest/v1/event/book/${book_session_id}/additional-info`
        : `/content/v1/event/book/${book_session_id}/additional-info`,
      data: { additional_info, send_to_participant_email },
      token: getIdToken(),
    })
    return res.data.data
  } catch (error) {
    sendLog(error)
    throw error
  }
}

export async function getEventBookingDetail(book_session_id) {
  try {
    const { data } = await apiClient({
      method: 'GET',
      url: isGuestMode()
        ? `/content/guest/v1/event/book/${book_session_id}`
        : `/content/v1/event/book/${book_session_id}`,
      token: getIdToken(),
    })
    return data.data
  } catch (error) {
    sendLog(error)
    throw error
  }
}

export async function getCreatorEventList({
  creator_id = '',
  page = 1,
  sort_by = 'create_date',
  sort_direction = 'DESC',
  eventFilterStatus = ['upcoming'],
}) {
  let res = await apiClient({
    url: `/content/guest/v1/creator/${creator_id}/product/list${
      eventFilterStatus.length
        ? eventFilterStatus
            .map(
              (filterItem, i) =>
                `${i === 0 ? '?' : ''}event_status=${filterItem}`,
            )
            .join('&')
        : ''
    }`,
    method: 'GET',
    params: {
      limit: 5,
      page: page,
      sort_by: sort_by,
      sort: sort_direction,
      is_published: true,
      type: 'event',
      status: 1,
    },
  })

  return res.data
}

export const fetchPurchasedEvents = async ({
  eventStatus,
  pageParam = '',
  ...payload
}) => {
  try {
    const res = await apiClient({
      method: 'GET',
      url: '/content/v3/product/event/purchased',
      token: getIdToken(),
      params: {
        limit: libraryConst.defaultLimit,
        event_status: eventStatus,
        cursor: pageParam,
        ...payload,
      },
    })
    return res.data
  } catch (error) {
    sendLog(error)
  }
}

type TypeEventFilters = 'upcoming' | 'event_ended'

export async function getEventList({
  page = 1,
  limit = 10,
  filter = null,
}: {
  page?: number
  limit?: number
  filter?: TypeEventFilters[]
}) {
  try {
    return search({
      le: limit,
      pe: page,
      tab: 'event',
      filter_status: filter,
    })
  } catch (err) {
    sendLog(err)
  }
}

export const getEventDetail = async (
  product_uid: string | string[],
) => {
  return await apiClient({
    url: !isGuestMode()
      ? `/content/v2/product/${product_uid}/detail`
      : `/content/guest/v2/product/${product_uid}/detail`,
    method: 'GET',
    token: isGuestMode() ? null : getIdToken(),
  })
}

export const getProductEventDetail = async (
  product_uid: string | string[],
  pt?: string | string[],
) => {
  return await apiClient({
    url: !isGuestMode()
      ? `/content/v1/product/${product_uid}/event`
      : `/content/guest/v1/product/${product_uid}/event`,
    method: 'GET',
    token: isGuestMode() ? null : getIdToken(),
    params: {
      pt: pt,
    },
  })
}

export const getGuestEventBookingStatus = async (
  product_uid: string | string[],
) => {
  const { data } = await apiClient({
    url: `/order/guest/v1/booking/${product_uid}/status`,
    method: 'GET',
    headers: { 'Idempotent-Key': generateShortUuid() },
  })
  return data
}

export const getSeatLayout = async (product_event_uid: string) => {
  return await apiClient({
    url: `/content/v1/product/${product_event_uid}/seat`,
    method: 'GET',
    token: getIdToken(),
  })
}

export const checkEventAvailability = async (product) => {
  try {
    return await apiClient({
      url: !isGuestMode()
        ? `/content/v1/product/purchasable`
        : `/content/guest/v1/product/purchasable`,
      method: 'POST',
      token: getIdToken(),
      data: {
        products: [product],
      },
    })
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getEventTypes = async () => {
  return await apiClient({
    url: '/content/guest/v1/product/event_types',
    method: 'GET',
  })
}

export const getEventTypesForHomePage = async () => {
  return await apiClient({
    url: '/recommendation/guest/v1/homepage/event_types',
    method: 'GET',
  })
}

export interface IEventRecommendationParams {
  page?: number
  limit?: number
  event_types?: string
  cat_id?: string[] | string
  event_status?: string
  content_type?: string[] | string
  etid?: string[] | string
  province?: string[] | string
}

export const getEventRecommendation = async ({
  cat_id,
  page,
  limit,
  event_status,
  etid,
  content_type,
  province,
}: IEventRecommendationParams) => {
  let url = !isGuestMode()
    ? `/recommendation/v1/event?`
    : `/recommendation/guest/v1/event?`

  return await apiClient({
    url: url,
    method: 'GET',
    token: isGuestMode() ? null : getIdToken(),
    params: {
      page,
      limit,
      es: event_status,
      etid,
      cat_id,
      status: content_type,
      sources: !isGuestMode() ? 'recommendation' : '',
      province,
    },
  })
}
